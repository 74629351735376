import React from "react";
import { createBrowserRouter, RouterProvider,Navigate } from "react-router-dom";
import Home2 from "./routes/newRoutes/Home2";
import GameDetails2 from "./routes/newRoutes/GameDetails2";
import AllQuizes2 from "./routes/newRoutes/AllQuizes2";
import Leaderboard2 from "./routes/newRoutes/Leaderboard2";
import Login2 from "./routes/newRoutes/Login2";
import Signup2 from "./routes/newRoutes/Signup2";
import Account2 from "./routes/newRoutes/Account2";
import History2 from "./routes/newRoutes/History2";
import FindingOpponent2 from "./routes/newRoutes/FindingOpponent2";
import GamePlayDashboard2 from "./routes/newRoutes/GamePlayDashboard2";
import GameResults2 from "./routes/newRoutes/GameResults2";
import NoQuestionsFound2 from "./routes/newRoutes/NoQuestionsFound2";
import History3 from "./routes/newRoutes/History3";
import ErrorBoundary from "./components/newComponents/ErrorBoundary";
import PageNotFound from "./routes/newRoutes/PageNotFound";
import SubscribePage from "./components/SubscribePage/SubscribePage";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/:msisdn",
      element: <Home2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/subscribe",
      element: <SubscribePage/>,
      // errorElement: <ErrorBoundary />,
    },
    {
      path: "/all-quizes",
      element: <AllQuizes2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/leaderboard",
      element: <Leaderboard2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/login",
      element: <Login2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/signup",
      element: <Signup2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/account",
      element: <Account2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/details/:category/:categoryId",
      element: <GameDetails2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/search/opponent/:mode/:modePoint",
      element: <FindingOpponent2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/play/:mode/:modePoint",
      element: <GamePlayDashboard2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/:mode/results",
      element: <GameResults2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/oops",
      element: <NoQuestionsFound2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/history",
      element: <History3 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      // element: <PageNotFound />,
      element: <Navigate to="/subscribe" />,
      errorElement: <ErrorBoundary />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
